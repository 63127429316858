<template>
  <b-overlay :show="isFetching">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h6>
        {{ `${$t('fields.result')} (${filteredList.length})`}}
      </h6>
    </div>
    <b-table
      :items="filteredList"
      :fields="fields"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(createdAt)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(title)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(isActive)="data">
        <b-badge :variant="data.value? 'success': 'danger'">
          {{ data.value? $t('utils.status.active'): $t('utils.status.inactive')}}
        </b-badge>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
      </template>
    </b-table>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'CouponHistory',
  data() {
    return {
      fields: [
        '#',
        {
          key: 'createdAt',
          label: `${this.$t('fields.createdAt')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'title',
          label: `${this.$t('fields.title')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'startDate',
          label: `${this.$t('rewards.start_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'endDate',
          label: `${this.$t('rewards.end_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'userCoupon',
          label: `${this.$t('rewards.use_coupon')}`,
          class: 'text-right',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'isActive',
          label: `${this.$t('fields.status')}`,
          class: 'text-center',
        },
        {
          key: 'id',
          label: '-',
          width: '100',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.reward.isFetchingCouponHistory,
    }),
    ...mapGetters(['couponHistory', 'userInfo']),
    filteredList() {
      return this.couponHistory || []
    },
    agentId() {
      return this.userInfo.agentId
    },
  },
  watch: {
    agentId(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchCouponHistory']),
    fetchData() {
      if (this.agentId) {
        this.fetchCouponHistory(this.agentId)
      }
    },
  },
}
</script>